<template>
  <div class="feedback-success">
    <div class="header">
      <div class="title">
        <div class="img">
          <img src="@/assets/success-reserve-right.png" />
        </div>
        <div class="text">反馈成功</div>
      </div>
      <div class="subtitle">您的意见我们已成功反馈</div>
    </div>
    <div class="body">
      <div class="action">
        <div class="submit" @click="submit"><span>关闭</span></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FeedbackSuccess',
  methods: {
    submit() {
      this.$router.push('/')
    },
  },
}
</script>
<style lang="scss" scoped>
.feedback-success {
  background: url(../../assets/schedule-interview-header-bg.png) no-repeat;
  height: 100vh;
  background-color: #f5f5f5;
  background-size: 100%;
  .header {
    padding-top: 32px;
    padding-left: 30px;
    padding-bottom: 11px;
    .title,
    .subtitle {
      font-family: PingFangSC-Semibold, PingFang SC;
      color: #ffffff;
    }
    .title {
      display: flex;
      height: 30px;
      line-height: 30px;
      .img {
        height: 28px;
        margin-right: 10px;
        img {
          height: 0.7rem;
        }
      }
      .text {
        font-size: 22px;
        font-weight: 600;
      }
    }
    .subtitle {
      margin-top: 7px;
      height: 21px;
      font-size: 15px;
      font-weight: 400;
      line-height: 21px;
    }
  }
  .body {
    .action {
      text-align: center;
      margin-top: 87px;
      .submit {
        margin: 0 auto;
        width: 320px;
        height: 52px;
        background: #4f8fff;
        border-radius: 26px;
        padding: 13px;
        span {
          width: 72px;
          height: 25px;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          line-height: 25px;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
